/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-mountains {
  background-color: #FFFFFF;
  background-image: linear-gradient(to top, rgba(255,255,255,0) 0%, #FFFFFF 100%), linear-gradient(to bottom, rgba(12,40,102,0) 0%, #0C2866 100%), url(/images/mountains.jpg), linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 50%, #0C2866 50%, #0C2866 100%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 100% 100px, 100% 100px, 1800px auto, 100% 100%;
  background-position: 50% 0%, 50% 100%, 50% 50%, 50% 0%;
}

@media screen and (min-width: 550px) {
  .bg-mountains {
    background-position: 50% 0%, 50% 100%, 50% 100%, 50% 0%;
  }
}

@media screen and (min-width: 1800px) {
  .bg-mountains {
    background-size: 100% 100px, 100% 100px, 100% auto, 100% 100%;
  }
}

@media screen and (min-width: 2200px) {
  .bg-mountains {
    background-position: 50% 0%, 50% 100%, 50% 50%, 50% 0%;
  }
}

.bg-ridge {
  background-color: #ffffff;
  background-image: url(/images/ridge.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.bg-gradient-light {
  background-color: #FFFFFF;
  background-image: linear-gradient(to bottom, rgba(173,214,242,0) 0%, rgba(173,214,242,1) 100%);
  background-size: 100% 150px;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.bg-gradient-medium {
  background-color: #0C2866;
  background-image: linear-gradient(to bottom, rgba(8,29,83,0) 0%, rgba(8,29,83,1) 100%);
  background-size: 100% 150px;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.bg-gradient-dark {
  background-color: #01143F;
  background-image: linear-gradient(to bottom, rgba(2,16,50,0) 0%, rgba(2,16,50,1) 100%);
  background-size: 100% 150px;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.quote {
  font-style: italic;
}

.quote::before {
  content: url('../../src/images/quotes.png');
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

.partner {
  width: calc(50% - 1rem); /* Adjust this value as needed */
  box-sizing: border-box;
}

/* Define classes for larger image sizes */
.larger-image {
    width: 400px; /* Adjust the width as needed */
    height: auto; /* This will maintain the aspect ratio */
}
